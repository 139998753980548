import * as React from "react";
import * as styles from "./style.module.sass";
import PromoCodeFormContainer from "../PromoCodeFormContainer";
import { GenericButton } from "../../../SharedComponent/GenericButtonWithIcon";
import { KeyboardArrowUpRounded, KeyboardArrowDownRounded } from "../../Icons";
import { useDispatch } from "react-redux";
import { useSelect } from "../../../../hooks/useSelect";
import { getSystemPromoCode } from "../../../../redux-store/actions/getSystemPromoCodesActions";
import { Input } from "antd";
import debounce from "lodash/debounce";
import closeIcon from "../../../../assets/icon-close.svg";
import searchIcon from "../../../../assets/search.svg";
interface IProps {
  totalPromos: number;
}

const PromoCodeHeader: React.FC<IProps> = (props) => {
  const [openDrawerState, setOpenDrawerState] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const dispatch = useDispatch();
  const { filters, promos, promoById } = useSelect(
    (state) => state.SystemPromoCodesReducer
  );

  const allPromosAreInactive = React.useMemo(
    () =>
      promos.every((promo) => promoById[promo].end_time < new Date().getTime()),
    [promoById]
  );

  const openDrawer = () => {
    setOpenDrawerState(true);
  };

  const closeDrawer = () => {
    setOpenDrawerState(false);
  };

  const sortPromoByRank = (
    order_by: ["rank:asc"] | ["rank:desc"] | undefined
  ) => {
    if (filters.status === "inactive" || allPromosAreInactive) {
      return;
    }
    handleFetchPromoCodes({ ...filters, order_by });
  };

  const handleFetchPromoCodes = (updatedFilters = filters, perPage = 15) => {
    dispatch(
      getSystemPromoCode({
        page: 0,
        per_page: perPage,
        filters: updatedFilters,
      })
    );
  };

  const debouncedSearchHandler = React.useMemo(
    () =>
      debounce((value: string) => {
        handleFetchPromoCodes({ ...filters, search: value.trim() });
      }, 1000),
    [filters, handleFetchPromoCodes]
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchText(value);
    debouncedSearchHandler(value);
  };

  const handleSearchKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && searchText.trim() !== "") {
      handleFetchPromoCodes({ ...filters, search: searchText.trim() });
    }
  };

  const clearSearch = () => {
    setSearchText("");
    handleFetchPromoCodes({ ...filters, search: "" });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.headWrapper}>
        <span className={styles.totalPromoCodesNum}>
          Total promo codes ({props.totalPromos})
        </span>
        <div className={styles.rightContainer}>
          <Input
            value={searchText}
            onChange={handleSearchChange}
            onKeyDown={handleSearchKeyPress}
            placeholder="Search by promo code name..."
            prefix={
              <img src={searchIcon} style={{ width: "20px", height: "20px" }} />
            }
            suffix={
              searchText && (
                <img
                  src={closeIcon}
                  alt="closeIcon"
                  onClick={clearSearch}
                  className={styles.closeIcon}
                />
              )
            }
            className={styles.searchInput}
          />
          <GenericButton
            label={"Add Promo Code"}
            className={styles.createBtn}
            IconClass={styles.icon}
            onClick={openDrawer}
            iconPath={require("../../../../assets/add.svg")}
          />
        </div>
        {openDrawerState && (
          <PromoCodeFormContainer
            onSubmitPromoTypes={"create"}
            open={openDrawerState}
            onClose={closeDrawer}
          />
        )}
      </div>
      <div className={styles.bodyWrapper}>
        <span className={styles.bodyText}>Brand info</span>
        <span className={styles.promoCodeNameText}>Promo code name</span>
        <div className={styles.rankWrapper}>
          <div className={styles.rankContent}>
            <span
              className={
                filters.order_by && allPromosAreInactive
                  ? styles.rankText
                  : styles.dammedRankText
              }
              onClick={() => sortPromoByRank(undefined)}
            >
              Rank
            </span>
            <div className={styles.arrowsWrapper}>
              <KeyboardArrowUpRounded
                className={
                  filters.status === "inactive" || allPromosAreInactive
                    ? styles.disabled
                    : styles.arrowUp
                }
                color={
                  filters.order_by?.includes("rank:asc") ? "#000" : "#84829E"
                }
                onClick={() => sortPromoByRank(["rank:asc"])}
              />
              <KeyboardArrowDownRounded
                className={
                  filters.status === "inactive" || allPromosAreInactive
                    ? styles.disabled
                    : styles.arrowUp
                }
                color={
                  filters.order_by?.includes("rank:desc") ? "#000" : "#84829E"
                }
                onClick={() => sortPromoByRank(["rank:desc"])}
              />
            </div>
          </div>
        </div>
        <span className={styles.promoCodeTypeText}>Promo code type</span>
        <span className={styles.bodyText}>Details</span>
      </div>
    </div>
  );
};

export default PromoCodeHeader;
