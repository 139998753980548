import { call, put, select, debounce } from "redux-saga/effects";
import { checkPromoAvailabilityAction } from "../../constants";
import { selectToken } from "../selectors";
import { checkPromoAvailabilityAPI } from "../../axios/checkPromoAvailabilityAPI";
import {
  checkPromoAvailabilityRejected,
  checkPromoAvailabilitySuccess,
  checkPromoAvailability,
} from "../actions/getSystemPromoCodesActions";
import { union } from "ts-action";

const actionTypes = union({ checkPromoAvailability });
function* checkPromoAvailabilitySaga({ payload }: typeof actionTypes) {
  try {
    const token: string = yield select(selectToken);
    yield call(checkPromoAvailabilityAPI, token, {
      promo_code: payload.promo_code,
      stores_ids: payload.stores_id,
    });
    yield put(checkPromoAvailabilitySuccess(true));
  } catch (e) {
    yield put(checkPromoAvailabilityRejected(e?.response?.data?.message));
  }
}

export function* watchCheckPromoAvailabilitySaga() {
  yield debounce(
    600,
    checkPromoAvailabilityAction.requested,
    checkPromoAvailabilitySaga
  );
}
