import axios from "./axios";
import * as Requests from "./requests";

export const checkPromoAvailabilityAPI = (
  token: string,
  data: { stores_ids: string[]; promo_code: string }
) => {
  return axios.post(Requests.checkPromoAvailabilityURL, data, {
    headers: {
      token,
    },
    baseURL: process.env.PICKUP_ENDPOINT,
  });
};
