import { call, put, select, takeLatest } from "redux-saga/effects";
import { getPromoCodeAction } from "../../constants";
import { selectToken } from "../selectors";
import { getSystemPromoCodesAPI } from "../../axios/get-system-promo-codes";
import {
  getPromoCode,
  getPromoCodeFailure,
  getPromoCodeSuccess,
} from "../actions/getSystemPromoCodesActions";
import { union } from "ts-action";
import { change } from "redux-form";
const actionType = union({ getPromoCode });
function* getPromoCodeSaga({ payload }: typeof actionType) {
  try {
    const token = yield select(selectToken);
    const res = yield call(getSystemPromoCodesAPI, token, 0, 15, {
      id: payload,
    });
    yield put(getPromoCodeSuccess(res.data.data[0]));
    yield put(
      change(
        "PromoCodeReduxForm",
        "stores_ids",
        res.data.data[0].stores.map((s) => s.id)
      )
    );
  } catch (e) {
    yield put(getPromoCodeFailure(e));
  }
}

export function* watchGetPromoCodeSaga() {
  yield takeLatest(getPromoCodeAction.requested, getPromoCodeSaga);
}
