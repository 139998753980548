import {
  ISinglePromoCode,
  getSystemPromoCodesAction,
  IPage,
  getPromoCodeAction,
  ACTION_RESET_PROMO_CODE,
  checkPromoAvailabilityAction,
} from "../../constants";
import { action, payload } from "ts-action";
export const getSystemPromoCode = action(
  getSystemPromoCodesAction.requested,
  payload<IPage>()
);
export const getSystemPromoCodeSuccess = action(
  getSystemPromoCodesAction.fulfilled,
  payload<{ data: ISinglePromoCode[]; promosHasMore: boolean; total: number }>()
);
export const getSystemPromoCodeFailed = action(
  getSystemPromoCodesAction.rejected,
  payload<Error>()
);
export const getPromoCode = action(
  getPromoCodeAction.requested,
  payload<string>()
);
export const resetPromoCode = action(ACTION_RESET_PROMO_CODE);
export const getPromoCodeSuccess = action(
  getPromoCodeAction.fulfilled,
  payload<ISinglePromoCode>()
);
export const getPromoCodeFailure = action(
  getPromoCodeAction.rejected,
  payload<Error>()
);
// export const getSystemPromoCode = (
//   page: constants.IPage
// ): IGetSystemPromoCodes => ({
//   type: constants.getSystemPromoCodesAction.requested,
//   payload: page
// });

// export const getSystemPromoCodeFailed = (
//   data: ISinglePromoCode[]
// ) => ({
//   type: constants.getSystemPromoCodesAction.fulfilled,
//   payload: data
// });

// export const getSystemPromoCodeFailed = (
//   error: Error
// ): IGetSystemPromoCodesFailed => ({
//   type: constants.getSystemPromoCodesAction.rejected,
//   payload: error
// });
export const checkPromoAvailability = action(
  checkPromoAvailabilityAction.requested,
  payload<{
    stores_id: string[];
    promo_code: string;
  }>()
);

export const checkPromoAvailabilitySuccess = action(
  checkPromoAvailabilityAction.fulfilled,
  payload<boolean>()
);

export const checkPromoAvailabilityRejected = action(
  checkPromoAvailabilityAction.rejected,
  payload<string>()
);
