import React, { useState } from "react";
import * as styles from "./style.module.sass";
import { Field, formValueSelector, Validator, change } from "redux-form";
import { ISelectItemData } from "../../../SharedComponent/RenderDropDownSelect";
import RenderSpecialMultipleSelectWithSearch from "../../../SharedComponent/RenderSpecialMultipleSelect/RenderSpecialMultipleSelectWithSearch";
import RenderDropDownSelectWithSearch from "../../../SharedComponent/RenderDropDownSelectWithSearch";
import Loader from "react-loader-spinner";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useSelect } from "../../../../hooks/useSelect";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getCountryStores } from "../../../../redux-store/actions/getCountryStoresActions";
import { useMemo } from "react";
import { ISingleCountryStore } from "../../../../axios/getBrands";
import { ICountry } from "../../../../types";

import { getCountries } from "../../../../redux-store/actions";
import { UseFormWatch } from "react-hook-form";
import { UploadBrands } from "../components";
import { useDeepCompareMemo } from "use-deep-compare";
import { selectPromocodeWatchers } from "../helpers/selectPromocodeWatchers";

const renderCountriesSelected = (item: ISelectItemData) => {
  return (
    <div className={styles.dropDownToggle}>
      <span>{item.label}</span>
    </div>
  );
};
const renderStoreSelected = (item: ISelectItemData) => {
  return (
    <div className={styles.dropDownToggle}>
      <span>{item?.label || "Select targeted brand"}</span>
      <ArrowDropDownIcon />
    </div>
  );
};

interface IProps {
  country_iso_code?: string;
  promo_id?: string;
  isEditing?: boolean;
  isReactivating?: boolean;
  isDuplicating?: boolean;
  formName: string;
  validateCountry?: Validator[];
  validateStores?: Validator[];
  disabled?: boolean;
  onlyOne?: boolean;
  setCheckBoxValue?: React.Dispatch<React.SetStateAction<boolean>> | any;
  setApplyCapping?: React.Dispatch<React.SetStateAction<boolean>> | any;
  setApplyMinEligibleInvoice?:
    | React.Dispatch<React.SetStateAction<boolean>>
    | any;

  value?: string[];
  watch?: UseFormWatch<any>;
  setValue?: (
    name: string,
    value: any,
    options?:
      | Partial<{
          shouldValidate: boolean;
          shouldDirty: boolean;
          shouldTouch: boolean;
        }>
      | undefined
  ) => void;
}
export const SelectStoresByCountry: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const selector = React.useCallback(formValueSelector(props.formName), [
    props.formName,
  ]);

  const {
    country_iso_code,
    stores_ids,
    countries,
    storesCountry,
    is_loading,
    editPromoFormData,
  } = useSelect((state) => ({
    editPromoFormData: {
      alreadySetStoresIds: state.SystemPromoCodesReducer?.current?.currentPromotionStore?.map(
        (el) => el.id
      ),
      alreadySetCountryCode:
        state?.form?.PromoCodeReduxForm?.values?.country_iso_code,
    },
    countries: state.countriesReducer.countries,
    storesCountry: state.CountryStoresReducer.stores,
    is_loading: state.CountryStoresReducer.is_loading,
    ...selector(state, "country_iso_code", "stores_ids"),
  }));

  React.useEffect(() => {
    dispatch(getCountries());
  }, []);

  useEffect(() => {
    if (
      props.value &&
      props.value.length > 0 &&
      props.value.length !== stores_ids.length
    ) {
      dispatch(change(props.formName, "stores_ids", props.value));
    }
  }, [props.value]);

  useEffect(() => {
    if (country_iso_code || props.country_iso_code) {
      dispatch(
        getCountryStores({
          country_code: country_iso_code,
          promo_id: props.promo_id,
        })
      );
    }
    if (props.onlyOne) {
      dispatch(change(props.formName, "stores_ids", undefined));
    }
  }, [country_iso_code, props.promo_id, props.country_iso_code, props.onlyOne]);

  const [selectedStoresMap, setSelectedStoresMap] = useState({});
  const watchers = useMemo(() => {
    if (props.watch) {
      return selectPromocodeWatchers(props?.watch);
    }
    return undefined;
  }, [props.watch]);
  const storesOutOfCountryStores = useDeepCompareMemo(() => {
    return watchers?.stores
      ? watchers?.stores
          ?.filter(
            (store) =>
              !storesCountry?.some((element) => element.value === store.id)
          )
          .map((store) => ({
            label: store.name,
            value: store.id,
            img: "",
          }))
      : storesCountry?.map((element) => ({
          label: element.name,
          value: element.id,
          img: "",
        }));
  }, [watchers?.stores, storesCountry, props.watch]);
  // getting selected Stores in a map from 'stores_ids' => form state
  useEffect(() => {
    const arrayOfSelectedStoresIds = Array.isArray(stores_ids)
      ? stores_ids
      : [];

    const map = [
      ...(storesCountry && storesCountry),
      ...(props.isEditing || props.isReactivating
        ? storesOutOfCountryStores
        : []),
    ].reduce((acc, next) => {
      if (next?.value && arrayOfSelectedStoresIds.includes(next?.value)) {
        return { ...acc, [next.value]: next };
      }
      return acc;
    }, {});
    setSelectedStoresMap(map);
  }, [
    stores_ids,
    storesCountry,
    props.isEditing,
    props.isReactivating,
    storesOutOfCountryStores,
  ]);

  // stores list of each country

  const storesSelectOptions = useMemo(() => {
    return storesCountry
      ? [
          ...storesCountry,
          ...(props.isEditing || props.isReactivating
            ? storesOutOfCountryStores
            : []),
        ]
          .reduce((acc, storeObject: ISingleCountryStore) => {
            // if store object is not in the selected elements
            // -> then add it to list of stores options
            return selectedStoresMap &&
              storeObject &&
              !selectedStoresMap[storeObject.value]
              ? [
                  ...acc,
                  {
                    label: storeObject.label,
                    value: storeObject.value,
                  },
                ]
              : acc;
          }, [])
          .sort((a, b) => a.label.localeCompare(b.label))
      : [];
  }, [
    selectedStoresMap,
    storesCountry,
    storesOutOfCountryStores,
    props.isEditing,
    props.isReactivating,
  ]);

  const selectAllOptionValue = useMemo(() => {
    return storesCountry.map((element) => element.value);
  }, [storesCountry]);

  // country select options
  const countrySelectItems = useMemo(
    () =>
      countries.map(
        (country: ICountry): ISelectItemData => ({
          value: country.country_code,
          label: country.EnName,
        })
      ),
    [countries]
  );

  const { alreadySetCountryCode } = editPromoFormData;
  return (
    <div className={styles.countryWrapper}>
      <Field
        name="country_iso_code"
        disabled={props.disabled}
        component={RenderDropDownSelectWithSearch as any}
        options={countrySelectItems}
        label="Select country to find brands"
        className={styles.select}
        validate={props.validateCountry}
        renderSelectToggle={renderCountriesSelected}
        alreadySetValue={alreadySetCountryCode}
      />
      {country_iso_code &&
        (is_loading ? (
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Loader
              type="Puff"
              color="#f09440"
              height={35}
              width={35}
              style={{ margin: "2rem 21rem" }}
            />
          </div>
        ) : (
          <React.Fragment>
            <Field
              onlyOne={props.onlyOne}
              name="stores_ids"
              component={RenderSpecialMultipleSelectWithSearch as any}
              options={[
                props.onlyOne
                  ? false
                  : {
                      label: "Select All",
                      value: selectAllOptionValue,
                    },
                ...storesSelectOptions,
              ].filter(Boolean)}
              label="Select targeted brand"
              disabled={props.disabled}
              className={styles.select2}
              renderSelectToggle={renderStoreSelected}
              validate={props.validateStores}
              countryISOCode={country_iso_code}
              selectedStoresMap={selectedStoresMap}
            />
            {!props.isEditing &&
              !props.isDuplicating &&
              !props.isReactivating && (
                <span style={{ fontSize: "14px", color: "red" }}>
                  *Don't add the same brands from the CSV to the dropdown
                  selection
                </span>
              )}
            {typeof props.watch !== "undefined" && (
              <UploadBrands
                setApplyCapping={props.setApplyCapping}
                isEditing={props.isEditing}
                watch={props.watch}
                setValue={props.setValue}
                setCheckBoxValue={props.setCheckBoxValue}
                isReactivating={props.disabled}
                setApplyMinEligibleInvoice={props.setApplyMinEligibleInvoice}
              />
            )}
          </React.Fragment>
        ))}
    </div>
  );
};
