import { PersistConfig, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { FormState, reducer as form } from "redux-form";
import { loadingBarReducer } from "react-redux-loading-bar";
import authReducer from "./reducers/authReducer";
import { brandsReducer } from "./reducers/brandsReducer";
import countriesReducer from "./reducers/countriesReducer";
import { brandDetailsReducer } from "./reducers/brandDetailsReducer";
import { editBrandReducer } from "./reducers/editBrandReducer";
import { exportFoodicsMenuReducer } from "./reducers/exportFoodicsMenuReducer";
import {
  brandConversionRateReducer,
  brandFailedOperationsNumberReducer,
  brandRedemptionNumberReducer,
  brandRedemptionRateReducer,
  brandRegisteredCustomerNumberReducer,
  brandReviewsNumberReducer,
  brandTempCustomersNumberReducer,
  brandUnresolvedIssuesNumberReducer,
  brandVisitsNumberReducer,
} from "./reducers/brandStatsReducers";
import { ILoadingReducerGenerator } from "./reducers/loadingReducerGenerator";
import paymentReducer from "./reducers/paymentReducer";
import paymentLogsReducer from "./reducers/paymentLogsReducer";
import reachCampaignsReducer from "./reducers/reachCampaignsReducer";
import reachQuotasReducer from "./reducers/reachQuotasReducer";
import { brandWizardInfoReducer } from "./reducers/brandWizardInfoReducer";
import { watchersReducer } from "./reducers/watcherReducer";
import CountryStoresReducer from "./reducers/countriesStoresReducer";
import checkPromoAvailabilityReducer from "./reducers/checkPromoAvailabilityReducer";
import CreatePromoCodeReducer from "./reducers/createPromoCodeReducer";
import { bannerCardsReducer } from "../Components/TargetedTools/redux-store/reducers/banner-cards";
import sendPushNotificationReducer from "./reducers/sendPushNotificationReducer";
import SystemPromoCodesReducer from "../Components/TargetedTools/redux-store/reducers/promotions";
import SendSmsMessageReducer from "./reducers/sendSmsMessageReducer";
import { announcementsReducer } from "../Components/TargetedTools/redux-store/reducers/announcements";
import { tagsReducer } from "../Components/TargetedTools/redux-store/reducers/tags";
import { categoriesReducer } from "../Components/TargetedTools/redux-store/reducers/categories";
import { commissionsReducer } from "../Components/TargetedTools/redux-store/reducers/commissions";
import { pushNotificationsReducer } from "../Components/TargetedTools/redux-store/reducers/pushNotificationReducer";
import { smsCampaignsReducer } from "../Components/TargetedTools/redux-store/reducers/smsCampaignsReducer";
import { promotedBrandsReducer } from "./reducers/promotedBrandsReducer";
import { promotedSectionTitleReducer } from "./reducers/promotedSectionTitleReducer";
import { cashbackDetailsReducer } from "./reducers/cashbackDetailsReducer";
import { getAdminsByRoleReducer } from "../Components/TargetedTools/redux-store/reducers/getAdminsByRoleReducer";
import { actionCenterBrandsReducer } from "./reducers/actionCenterBrandsReducer";
import { getPointsOfContactsReducer } from "./reducers/getPointsOfContactsReducer";
import { getContactTemplatesReducer } from "./reducers/getContactTemplatesReducer";
import { resolveTasksReducer } from "./reducers/resolveTasksReducer";
import { getResolvedActionsReducer } from "./reducers/getResolvedActionsReducer";
import { posProvidersReducer } from "./reducers/posProvidersReducer";
import getBrandPosEntitiesReducer from "./reducers/getBrandPosEntitiesReducer";
import promoCodeReducer from "./reducers/promoCodeReducer";
export interface IFormState {
  paidForm: FormState;
  trialForm: FormState;
  addBrand: FormState;
  editBrand: FormState;
  confirmationForm: FormState;
  paymentForm: FormState;
}

export interface IBrandStatsState extends ILoadingReducerGenerator {
  number: number;
}

const rootReducer = combineReducers({
  loadingBar: loadingBarReducer,
  form,
  authReducer: persistReducer(
    {
      storage,
      key: "auth",
    },
    authReducer
  ),
  brandsReducer,
  countriesReducer,
  brandDetailsReducer,
  commissionsReducer,
  editBrandReducer,
  brandWizardInfoReducer,
  brandFailedOperationsNumberReducer,
  brandRegisteredCustomerNumberReducer,
  brandReviewsNumberReducer,
  brandConversionRateReducer,
  brandRedemptionRateReducer,
  brandRedemptionNumberReducer,
  brandTempCustomersNumberReducer,
  brandVisitsNumberReducer,
  brandUnresolvedIssuesNumberReducer,
  paymentReducer,
  paymentLogsReducer,
  reachCampaignsReducer,
  reachQuotasReducer,
  watchersReducer,
  CountryStoresReducer,
  checkPromoAvailabilityReducer,
  CreatePromoCodeReducer,
  SendSmsMessageReducer,
  sendPushNotificationReducer,
  bannerCardsReducer,
  announcementsReducer,
  SystemPromoCodesReducer,
  tagsReducer,
  categoriesReducer,
  pushNotificationsReducer,
  smsCampaignsReducer,
  exportFoodicsMenuReducer,
  promotedBrandsReducer,
  promotedSectionTitleReducer,
  cashbackDetailsReducer,
  getAdminsByRoleReducer,
  actionCenterBrandsReducer,
  getPointsOfContactsReducer,
  getContactTemplatesReducer,
  resolveTasksReducer,
  getResolvedActionsReducer,
  posProvidersReducer,
  getBrandPosEntitiesReducer,
  promoCodeReducer,
});

type rootReducer = typeof rootReducer;

type returnTypeInferrer<T> = T extends (state, action) => infer U ? U : never;
type rootState = returnTypeInferrer<rootReducer>;
export type IRootReducerState = rootState;

const persistorOptions: PersistConfig = {
  key: "root",
  storage,
  whitelist: ["appReducer"],
};

const persistedReducer = persistReducer(persistorOptions, rootReducer);

export default persistedReducer;
