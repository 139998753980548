import { on, reducer } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { getPromoCodeAction, ISinglePromoCode } from "../../constants";
import {
  getPromoCodeSuccess,
  resetPromoCode,
} from "../actions/getSystemPromoCodesActions";

interface IPromocodeState {
  promoCode: ISinglePromoCode;
}
const initialState: IPromocodeState = {
  promoCode: {
    ar_desc: "",
    ar_title: "",
    end_time: 0,
    id: "",
    category: "discount",
    created_at: 0,
    customers_count: 0,
    customers_file_name: "",
    customers_file_names: [],
    desc: "",
    discount_value: 0,
    expired_at: "",
    label: "",
    max_cashback_value: 0,
    max_discount_value: 0,
    max_usage_times: 0,
    min_threshold: 0,
    customers_file_path: "",
    is_compensational: false,
    is_exclusive: 0,
    multiplier_value: "",
    ordering_type: "",
    rank: 0,
    start_time: 0,
    stores: [],
    stores_fund_percentage: [],
    promo_code: "",
    title: "",
    type: "",
    suggested: 0,
    targeting_method: "",
    updated_at: 0,
    user_id: "",
    allowed_payment_method: undefined,
    expired_by_admin_at: 0,
    cashback_value: 0,
    promo_merchant_visibility: undefined,
  },
};
export default withLoadingReducer<IPromocodeState>(
  reducer<IPromocodeState>(
    [
      on(getPromoCodeSuccess, (state, { payload }) => ({
        ...state,
        promoCode: { ...payload },
      })),
      on(resetPromoCode, (state, {}) => ({
        ...state,
        promoCode: initialState.promoCode,
      })),
    ],
    initialState
  ),
  getPromoCodeAction
);
