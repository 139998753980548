import React, { memo, useEffect } from "react";
import * as styles from "./style.module.sass";
import { Drawer } from "@material-ui/core";
import Loader from "react-loader-spinner";
import { IPromoCodeFormContainer } from "../../types";
import PromoCodeReduxForm from "./form";
import { useSelect } from "../../../../hooks/useSelect";
import { LoadingStatus } from "../../../../redux-store/reducers/withLoadingState";
import { clearCurrentPromotionStore } from "../../redux-store/actions/promoCodeActions";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

const PromoCodeFormContainer: React.FC<IPromoCodeFormContainer> = ({
  setPromotionStoresIsLoading,
  open,
  promotionStoresIsLoading,
  promoCode,
  onClose,
  country_iso_code,
  isEditing,
  formLabel,
  onSubmitPromoTypes,
  isReactivating,
  isDuplicating,
}) => {
  const dispatch = useDispatch();
  const {
    promoCodeReducer: { loadingStatus },
  } = useSelect((state) => ({
    promoCodeReducer: state.promoCodeReducer,
  }));
  const { setValue, reset } = useForm();
  useEffect(() => {
    if (loadingStatus === LoadingStatus.success) {
      setPromotionStoresIsLoading?.(false);
    }
  }, [loadingStatus]);
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => {
        setValue("stores_ids", []);
        setValue("country_iso_code", "");
        setValue("applySplitForAllParameterValue", undefined);
        reset();
        dispatch(clearCurrentPromotionStore());
        onClose();
        setPromotionStoresIsLoading?.(false);
      }}
    >
      <div className={styles.wrapper}>
        {promotionStoresIsLoading ? (
          <div className={styles.loader}>
            <Loader type="Puff" color="#f09440" height={100} width={100} />
          </div>
        ) : (
          <PromoCodeReduxForm
            onSubmitPromoTypes={onSubmitPromoTypes}
            setPromotionStoresIsLoading={setPromotionStoresIsLoading}
            promotionStoresIsLoading={promotionStoresIsLoading}
            promo_type={promoCode?.label}
            open={open}
            formLabel={formLabel}
            close={onClose}
            isEditing={isEditing}
            isDuplicating={isDuplicating}
            ordering_promotion_id={promoCode?.id}
            typeOfPromo={promoCode?.type}
            isReactivating={isReactivating}
            promo_merchant_visibility={promoCode?.promo_merchant_visibility}
            initialValues={{
              ...promoCode,
              country_iso_code,
              stores_ids: promoCode?.stores.map((s) => s.id),
              type: promoCode?.type,
            }}
          />
        )}
      </div>
    </Drawer>
  );
};

export default memo(PromoCodeFormContainer);
