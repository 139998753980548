export const login = "/api/shopxadmin/login";
export const logDashboardPreview = "/api/dashboard-preview/record";
export const logout = "/api/shopxadmin/logout";
export const getCountries = "/api/getCountries";
export const getBrands = "/api/stores-grouped";
export const getBrandsStatistics = "/api/stores-statistics";
export const getOrderingPortalStatusURL = (store_id) =>
  `/api/admin/web-portal/status/${store_id}`;
export const editOrderingPortalStatusURL = (store_id) =>
  `/api/admin/web-portal/status/${store_id}`;
export const editOrderingQRPortalStatusURL = (store_id) =>
  `api/admin/qr-portal/status/${store_id}`;
export const getBrand = (store_id) => `/api/stores-managers/${store_id}`;
export const commissionUrl = (store_id) =>
  `api/admin/store/ordering-commissions/${store_id}`;
export const getPaymentLogs = (store_id) =>
  `/api/stores-payment-logs/${store_id}`;
export const addBrand = "/api/stores";
export const getFleetStatus = (store_id) =>
  `/api/admin/delivery-management/status/${store_id}`;
export const getStoreInfo = (store_id) =>
  `/api/admin/store/ordering-info/${store_id}`;
export const addManager = "/api/managers";
export const editManager = (manager_id) => `/api/managers/${manager_id}`;
export const getManager = (manager_id) => `/api/managers/${manager_id}`;
export const editBrand = (store_id) => `/api/stores/${store_id}`;
export const conversionRate = "/api/stores-conversion-rate";
export const redemptionRate = "/api/stores-redemptions-rate";
export const registeredCustomersNumber =
  "/api/stores-customers-number-registered";
export const tempCustomersNumber = "/api/stores-customers-number-unregistered";
export const visitsNumber = "/api/stores-visits-number";
export const redemptionNumber = "/api/stores-redemptions-number";
export const failedOperationsNumber = "/api/stores-failed-operations-number";
export const unresolvedIssuesNumber = "/api/stores-unresolved-issues-number";
export const reviewsNumber = "/api/stores-reviews-number";
export const campaignRequests = "/api/reach/campaigns";
export const verifyPayment = (store_id) =>
  `/api/stores-verify-payment/${store_id}`;
export const confirmPayment = (store_id) =>
  `/api/stores-confirm-payment/${store_id}`;
export const getQuotas = "/api/reach/purchased-quotas";
export const acceptCampaign = (id: string) =>
  `/api/reach/accept-reject-campaigns/${id}`;
export const rejectCampaign = (id: string) =>
  `/api/reach/accept-reject-campaigns/${id}`;
export const acceptQuota = (id: string) =>
  `/api/reach/accept-reject-quotas/${id}`;
export const rejectQuota = (id: string) =>
  `/api/reach/accept-reject-quotas/${id}`;
export const campaignBranches = "/api/reach/campaigns-branches";
export const getCurrentStep = "/api/wizard-steps/current";
export const sendWizardInvitation = "api/wizard/send-invitation";
export const getBrandWizardToken = (store_id) =>
  `/api/wizard/store-token?store_id=${store_id}`;
export const getUXCamStatus = "/api/uxcam/get-status";
export const changeUXCamStatus = "/api/uxcam/change-status";
export const orderingStatus = (store_id) =>
  `/api/admin/ordering-status/${store_id}`;
export const getWatchers = "api/watcher";
export const addWatchers = "api/watcher";
export const getCountriesStoresURL = "/api/admin/country-active-stores";
// export const PromoCodesURL = "/api/admin/pickup/promo-code/csv";
export const CreateUniversalPromoCodeURL = "/api/admin/promo-code/universal";
export const checkPromoAvailabilityURL = "/api/admin/promo-code/check-availability";

export const sendSmsURL = "/api/sms/create-campaign/csv";
export const sendNotificationURL = "/api/push/create-campaign/csv";

export const getSystemPromoCodesURL = "/api/admin/promo-code/get-promotions";
// export foodics menu
export const exportFoodicsMenuURL = "/api/foodics/menu/export";

// get merchants

export const getMerchantsURL = "/api/merchants";

// ordering GMV type
export const orderingGMVTypeURL = (store_id) =>
  `/api/stores/${store_id}/ordering-gmv-type`;

// active deal
export const getActiveMerchantDealsURL = (store_id) =>
  `/api/stores/${store_id}/active-deals`;
export const editActiveMerchantDealsURL = (store_id, service_type) =>
  `/api/stores/${store_id}/active-deals/${service_type}`;

// promoted brands
export const AddOrDeleteToPromotedBrandsList = (store_id: string) => {
  return `/api/stores/${store_id}/promoted-store`;
};
export const getPromotedBrandsList = "/api/stores/promoted-stores";
export const getPromotedSectionTitle =
  "/api/stores/promoted-stores/section-title";
export const cashbackDetailsURL = "/api/admin/store/cashback-details";

export const adminsByRoleURL = "/api/shopxadmin/admins-by-role";
export const reassiginAccountManagerURL =
  "/api/shopxadmin/assign-account-manager";

export const getAccountManagerURL = (store_id: string) =>
  `api/stores-managers/${store_id}`;

export const getBrandsWithTasksURL = "/api/ams/task-center";
export const getPointsOfContactsURL = "/api/shopxadmin/points-of-contacts";
export const getContactTemplateURL = "/api/contact-template";
export const resolveTasksURL = "/api/ams/task-center/resolve-tasks";
export const getResolvedActionsURL = "/api/ams/task-center/resolved-actions";

export const posEntitiesURL = "/api/manager/pos-entities";
export const genBrandPosPaymentMethodURL = "api/generate-payment-method";
export const brandPosIntegrationStatusURL = (
  status: "loyalty-integration-status" | "ordering-integration-status"
) => `/api/manager/pos-entities/${status}`;
