import { all } from "redux-saga/effects";

import * as SAGAS from "./sagas";
import { watchGetBannerCardsSaga } from "../Components/TargetedTools/redux-store/sagas/getBannerCardsSaga";
import { watchReorderBannerCardSaga } from "../Components/TargetedTools/redux-store/sagas/reorderBannerCardSaga";
import { watchDeleteBannerCardSaga } from "../Components/TargetedTools/redux-store/sagas/deleteBannerCardSaga";
import { watchCreateBannerCardSaga } from "../Components/TargetedTools/redux-store/sagas/createBannerCardSaga";
import { watchCreateAnnouncementSaga } from "../Components/TargetedTools/redux-store/sagas/createAnnouncementSaga";
import { watchGetAnnouncementsSaga } from "../Components/TargetedTools/redux-store/sagas/getAnnouncementsSaga";
import { watchDeleteAnnouncementSaga } from "../Components/TargetedTools/redux-store/sagas/deleteAnnouncementSaga";
import { watchGetStoreTagsSaga } from "../Components/TargetedTools/redux-store/sagas/getStoreTagsSaga";
import { watchCreateStoreTagsSaga } from "../Components/TargetedTools/redux-store/sagas/createStoreTagsSaga";
import { watchDeleteStoreTagSaga } from "../Components/TargetedTools/redux-store/sagas/deleteStoreTagSaga";
import { watchEditTagSaga } from "../Components/TargetedTools/redux-store/sagas/editStoreTagSaga";
import { watchGetCategoriesSaga } from "../Components/TargetedTools/redux-store/sagas/getCategoriesSaga";
import { watchCreateCategorySaga } from "../Components/TargetedTools/redux-store/sagas/createCategorySaga";
import { watchDeleteCategorySaga } from "../Components/TargetedTools/redux-store/sagas/deleteCategorySaga";
import { watchEditCategorySaga } from "../Components/TargetedTools/redux-store/sagas/editCategorySaga";
import { watchGetSMSCampaignsSaga } from "../Components/TargetedTools/redux-store/sagas/smsCampaignsSaga";
import { watchExpirePromoCodeSaga } from "../Components/TargetedTools/redux-store/sagas/expirePromoCodeSaga";
import { watchCreateUniversalPromoCodeSaga } from "../Components/TargetedTools/redux-store/sagas/createUniversalPromoCodeSaga";
import { watchGetPromotionStoresSaga } from "../Components/TargetedTools/redux-store/sagas/getPromotionStores";
import { watchCreatePromoCodeSaga } from "../Components/TargetedTools/redux-store/sagas/createPromoCodeSaga";
import { watchEditPromoCodeSaga } from "../Components/TargetedTools/redux-store/sagas/editPromoCodeSaga";
import { watchGetPushNotificationsSaga } from "../Components/TargetedTools/redux-store/sagas/getPushNotificationSaga";
import { watchCreatePushNotificationSaga } from "../Components/TargetedTools/redux-store/sagas/createPushNotification";
import { watchGetCommissionsSaga } from "../Components/TargetedTools/redux-store/sagas/getCommissions";
import { watchEditCommissionsSaga } from "../Components/TargetedTools/redux-store/sagas/editCommissions";
import { watchEditPromotedBrandSaga } from "./sagas/editPromotedStoreSaga";
import { watchPromotedBrandsSaga } from "./sagas/promotedBrandsSage";
import { watchEditPromotedSectionTitleSaga } from "./sagas/editPromotedSectionTitleSage";
import { watchPromotedSectionTitleSage } from "./sagas/getPromotedSectionTitleSaga";
import { watchGetAdminsByRoleSaga } from "../Components/TargetedTools/redux-store/sagas/getAdminsByRoleSaga";
import { watchAssignAccountManagerSaga } from "../Components/TargetedTools/redux-store/sagas/reassiginAccountManagerSaga";
import { watchGetAccountManagerSaga } from "../Components/TargetedTools/redux-store/sagas/getAccountManagerSaga";
import { watchGenBrandPosPaymentMethodSaga } from "./sagas/genBrandPosPaymentMethodSaga";
export default function* rootSaga() {
  yield all([
    SAGAS.watchLoginSaga(),
    SAGAS.watchDashboardPreviewLoggerSaga(),
    SAGAS.watchLogoutSaga(),
    SAGAS.watchBrandsSaga(),
    SAGAS.watchGetCountriesSaga(),
    SAGAS.watchBrandDetailsSaga(),
    SAGAS.watchAddBrandSaga(),
    SAGAS.watchEditBrandSaga(),
    SAGAS.brandConversionRateSaga(),
    SAGAS.brandFailedOperationsNumberSaga(),
    SAGAS.brandRedemptionNumberSaga(),
    SAGAS.brandRedemptionRateSaga(),
    SAGAS.brandRegisteredCustomerNumberSaga(),
    SAGAS.brandReviewsNumberSaga(),
    SAGAS.brandTempCustomersNumberSaga(),
    SAGAS.brandVisitsNumberSaga(),
    SAGAS.brandUnresolvedIssuesNumberSaga(),
    SAGAS.watchVerifyPaymentSaga(),
    SAGAS.watchConfirmPaymentSaga(),
    SAGAS.watchPaymentLogsSaga(),
    SAGAS.watchGetReachCampaignsSaga(),
    SAGAS.watchGetReachQuotasSaga(),
    SAGAS.watchGetBrandWizardInfoSaga(),
    SAGAS.watchAddWatchersSaga(),
    SAGAS.watchGetWatchersSaga(),
    SAGAS.watchGetCountriesStoresSaga(),
    SAGAS.watchCheckPromoAvailabilitySaga(),
    watchGetBannerCardsSaga(),
    SAGAS.watchSendSmsMessageSaga(),
    SAGAS.watchSendPushNotificationSaga(),
    SAGAS.watchSystemGetPromoCodesSaga(),
    SAGAS.watchGetPromoCodeSaga(),
    SAGAS.watchGetCashbackDetailsSaga(),
    SAGAS.watchEditCashbackDetailsSaga(),
    SAGAS.watchCreateCashbackDetailsSaga(),
    SAGAS.watchGetBrandsWithTasksSage(),
    SAGAS.watchGetContactTemplatesSaga(),
    SAGAS.watchGetPointsOfContactsSaga(),
    SAGAS.watchResolveTasksSaga(),
    SAGAS.watchGetResolvedActionsSaga(),
    watchReorderBannerCardSaga(),
    watchDeleteBannerCardSaga(),
    watchCreateBannerCardSaga(),
    watchCreateAnnouncementSaga(),
    watchGetCommissionsSaga(),
    watchEditCommissionsSaga(),
    watchGetAnnouncementsSaga(),
    watchDeleteAnnouncementSaga(),
    watchGetStoreTagsSaga(),
    watchCreateStoreTagsSaga(),
    watchDeleteStoreTagSaga(),
    watchEditTagSaga(),
    watchGetCategoriesSaga(),
    watchCreateCategorySaga(),
    watchDeleteCategorySaga(),
    watchGetSMSCampaignsSaga(),
    watchEditCategorySaga(),
    watchExpirePromoCodeSaga(),
    watchCreateUniversalPromoCodeSaga(),
    watchGetPromotionStoresSaga(),
    watchCreatePromoCodeSaga(),
    watchEditPromoCodeSaga(),
    watchGetPushNotificationsSaga(),
    watchCreatePushNotificationSaga(),
    SAGAS.watchExportFoodicsMenuSaga(),
    watchEditPromotedBrandSaga(),
    watchPromotedBrandsSaga(),
    watchEditPromotedSectionTitleSaga(),
    watchPromotedSectionTitleSage(),
    watchEditPromotedSectionTitleSaga(),
    watchGetAdminsByRoleSaga(),
    watchAssignAccountManagerSaga(),
    watchGetAccountManagerSaga(),
    watchGenBrandPosPaymentMethodSaga(),
    SAGAS.watchGetPosProvidersSaga(),
    SAGAS.watchGetBrandPosEntitiesSaga(),
    SAGAS.watchDeleteBrandPosEntitySaga(),
    SAGAS.watchCreateBrandPosEntitySaga(),
    SAGAS.watchUpdateBrandPosEntitySaga(),
    SAGAS.watchExportBrandPosMenuSaga(),
    SAGAS.watchGetBrandPosIntegrationStatusSaga(),
  ]);
}
