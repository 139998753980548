import { on, reducer } from "ts-action";
import { withLoadingReducer } from "./withLoadingState";
import { checkPromoAvailabilityAction } from "../../constants";
import {
  checkPromoAvailabilityRejected,
  checkPromoAvailabilitySuccess,
} from "../actions/getSystemPromoCodesActions";

interface IPromoCodesState {
  is_loading: boolean;
  error: string;
  valid_promo_code: boolean;
}

const initialState: IPromoCodesState = {
  is_loading: false,
  error: "",
  valid_promo_code: false,
};

// const StorePromoCodesReducerAvailability = (
//   state = initialState,
//   action: IGetStorePromoCodesTypes
// ): IPromoCodesReducer => {
//   switch (action.type) {
//     case constants.getPromoCodeStoresAction.requested:
//       return {
//         ...state,
//         is_loading: true,
//       };
//     case constants.getPromoCodeStoresAction.fulfilled:
//       return {
//         ...state,
//         valid_promo_code: action.payload as boolean,
//         is_loading: false,
//       };
//     case constants.getPromoCodeStoresAction.rejected:
//       return {
//         ...state,
//         valid_promo_code: false,
//         is_loading: false,
//         error: action.payload as string,
//       };
//     default:
//       return state;
//   }
// };

export default withLoadingReducer<IPromoCodesState>(
  reducer<IPromoCodesState>(
    [
      on(checkPromoAvailabilitySuccess, (state, { payload }) => ({
        ...state,
        valid_promo_code: payload,
      })),
      on(checkPromoAvailabilityRejected, (state, { payload }) => ({
        ...state,
        error: payload,
      })),
    ],
    initialState
  ),
  checkPromoAvailabilityAction
);
